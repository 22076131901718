import { setUserInfo, getUserInfo } from './storage';

const userCanWrite = () => {
  const user = getUserInfo();
  
  const writeRoles = {
    'admin': true,
    'product_chief': true,
    'investor_chief': false,
    'product_manager': false,
    'guest': false
  };
  if (!user)
    return false;
  let res = false;
  user.groups.forEach((item) => {
    if (writeRoles[item.name])
      res = true;
  });
  return res;
};

const userCanWriteOrders = () => {
  const user = getUserInfo();
  
  const writeRoles = {
    'admin': true,
    'product_chief': true,
    'investor_chief': false,
    'product_manager': false,
    'guest': true
  };
  if (!user)
    return false;
  let res = false;
  user.groups.forEach((item) => {
    if (writeRoles[item.name])
      res = true;
  });
  return res;
};












const userCanView = () => {
  const user = getUserInfo();

  const viewRoles = {
    'admin': true,
    'product_chief': true,
    'investor_chief': true,
    'product_manager': true,
    'guest': true,
    'intermediario': false
  };

  if (!user || !user.groups || user.groups.length === 0) {
    return false;
  }

  return user.groups.some((item) => viewRoles[item.name]);
};

const userCanViewDemandBook = () => {
  const user = getUserInfo();
  
  const viewRoles = {
    'admin': true,
    'product_chief': false,
    'investor_chief': false,
    'product_manager': true,
    'guest': true,
    "intermediario":false
  };
  if (!user)
    return false;

  return user.groups.some((item) => viewRoles[item.name]);
};

const userInfo = () => {
  const user = getUserInfo();

  return user;
};

export {
  userCanViewDemandBook,
  userInfo,
  userCanView,
  userCanWrite,
  userCanWriteOrders,
};