import { applyMiddleware, createStore, compose } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
// import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import reducer from './reducer';
import rootSaga from './sagas';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = applyMiddleware(routerMiddleware(history), sagaMiddleware);

const initialState = {};

const store = createStore(
  reducer(history),
  initialState,
  composeEnhancers(middlewares),
);

sagaMiddleware.run(rootSaga);

export default store;
