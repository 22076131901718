import React from "react";
import PropTypes from "prop-types";
import Login from "../../components/Forms/LoginForm";
import { logoCoreCapital } from "../../images";
import { Row, Col, Typography } from "antd";
import { Layout } from "antd";
import injectSheet from "react-jss";
import styles from "./styles";
import HelpButton from "./utils/helpBtm";
const { Content } = Layout;
const { Title } = Typography;

class SignIn extends React.PureComponent {
  state = {
    username: "",
    password: "",
    iconLoading: false,
  };

  handleSubmit = (event) => {
    this.setState({ iconLoading: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <img
          className={classes.logo}
          src={logoCoreCapital}
          alt="CORE Capital"
        />
        <Content className={classes.containerLoginScreen}>
          <div
            justify="center"
            alignItems="center"
            className={classes.loginWrapper}
          >
            <h1 className={classes.loginTitle}>Iniciar Sesión</h1>
            <Login onLogged={this.props.onLogged} />
          </div>
        </Content>
        <span
          style={{
            position: "fixed",
            bottom: 20,
            left: 30,
            color:"#111"
          }}
          class="mr-8"
        >
          <strong>CORE Capital SAF © 2024</strong> (511) 748-4547 /{" "}
          <a
            href="#contactanos"
            class="glightbox-mod"
            data-glightbox="width:900px;"
            data-gallery="contactanos-footer-home"
            data-height="auto"
            data-draggable="false"
         
          >
            info@corecapital.com.pe
          </a>
        </span>
        {/* <HelpButton /> */}
      </React.Fragment>
    );
  }
}

SignIn.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default injectSheet(styles)(SignIn);
