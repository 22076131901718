import React, { useEffect, useState } from "react";
import { Table, Card } from "antd";

import injectSheet from "react-jss"; // Importa injectSheet
import styles from "./style";
import api from "../../utils/api";
const data1 = [
  {
    item: "TOTAL GENERAL",
    2027: 49863617.501962,
    2026: 8413683.548839,
    2025: 39777123.399338,
    2024: 157295410.813579,
    2023: 49808101.2,
    2022: 41488343.85,
    "Composición 23": "100",
    "Composición 22": "100",
    "22 vs 23": 20.053240447678174,
  },
  {
    item: "TOTAL USA",
    2027: 49863617.501962,
    2025: 28277123.399337,
    2024: 70075667.118888,
    2023: 26792984.99,
    2022: 15251316.3,
    "Composición 23": 53.792424012341186,
    "Composición 22": 36.76048471623916,
    "22 vs 23": 75.67654137498938,
  },
  {
    item: "TOTAL PERU",
    2026: 8413683.548839,
    2025: 11500000.000001,
    2024: 87219743.694691,
    2023: 23015116.21,
    2022: 26237027.55,
    "Composición 23": 46.207575987658814,
    "Composición 22": 63.23951528376084,
    "22 vs 23": -12.280016605768285,
  },
  {
    item: "TOTAL FONDOS",
    2027: 49863617.501962,
    2026: 8413683.548839,
    2025: 39777123.399338,
    2024: 126935410.813579,
    2023: 34290881.2,
    2022: 11073310.85,
    "Composición 23": 68.84599166370148,
    "Composición 22": 26.690173244888395,
    "22 vs 23": 209.67144031723808,
  },
  {
    item: "TOTAL FONDOS EQUITY",
    2027: 49863617.501962,
    2026: 8413683.548839,
    2025: 39777123.399338,
    2024: 95135410.813579,
    2023: 34290881.2,
    2022: 11073310.85,
    "Composición 23": 68.84599166370148,
    "Composición 22": 26.690173244888395,
    "22 vs 23": 209.67144031723808,
  },
  {
    item: "TOTAL BONOS",
    2024: 30360000.0,
    2023: 15517220.0,
    2022: 30415033.0,
    "Composición 23": 31.154008336298514,
    "Composición 22": 73.3098267551116,
    "22 vs 23": -48.981742022111234,
  },
  {
    item: "TOTAL BONOS EQUITY",
  },
  {
    item: "TOTAL RENTA FIJA",
    2024: 30360000.0,
    2023: 15517220.0,
    2022: 30415033.0,
    "Composición 23": 31.154008336298514,
    "Composición 22": 73.3098267551116,
    "22 vs 23": -48.981742022111234,
  },
  {
    item: "TOTAL RENTA VARIABLE",
    2027: 49863617.501962,
    2026: 8413683.548839,
    2025: 39777123.399338,
    2024: 126935410.813579,
    2023: 34290881.2,
    2022: 11073310.85,
    "Composición 23": 68.84599166370148,
    "Composición 22": 26.690173244888395,
    "22 vs 23": 209.67144031723808,
  },
];

const formatTableData = (data) => {
  return data.map((item) => {
    const formattedItem = { ...item };

    formattedItem["Composición 22"] = formattedItem["Composición 22"]
      ? `${Number(formattedItem["Composición 22"]).toFixed(2)}%`
      : undefined;
    formattedItem["Composición 23"] = formattedItem["Composición 23"]
      ? `${Number(formattedItem["Composición 23"]).toFixed(2)}%`
      : undefined;
    formattedItem["22 vs 23"] = formattedItem["22 vs 23"]
      ? `${Number(formattedItem["22 vs 23"]).toFixed(2)}%`
      : undefined;

    Object.keys(formattedItem).forEach((key) => {
      if (/^\d{4}$/.test(key) && typeof formattedItem[key] === "number") {
        formattedItem[key] = formattedItem[key].toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    });

    return formattedItem;
  });
};
function ResumenComite({ classes }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/orders/transaction-overview");

        setData(response.data);
      } catch (error) {
        console.error("Error al obtener datos de la API", error);
      }
    };

    fetchData();
  }, []);



  const formattedData = formatTableData(data);
  const dataSource = formattedData.map((item, index) => ({
    key: index,
    ...item,
  }));
  const getYearColumns = (data) => {
    const yearColumns = [];
    const years = [2024,2023,2022];

    years.forEach((year) => {
      yearColumns.push({
        title: year,
        dataIndex: year,
        key: year,
      });
    });

    return yearColumns;
  };
  const columns = [
    {
      title: "Totales",
      dataIndex: "item",
      key: "item",
      width: 200,
    },
    ...getYearColumns(data),
    {
      title: "Composición 23",
      dataIndex: "Composición 23",
      key: "Composición 23",
    },
    {
      title: "Composición 22",
      dataIndex: "Composición 22",
      key: "Composición 22",
    },
    {
      title: "22 vs 23",
      dataIndex: "22 vs 23",
      key: "22 vs 23",
    },
  ];

  return (
    <div className={classes.container}>
      <Card title="Resumen Comité" className={classes.customCard}>
        <div className={classes.ordersTableWrapper}>
          <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            className={classes.flowTableWrapper}
            size="middle"
            pagination={false}
            scroll={{ x: 768 }}
          />
        </div>
      </Card>
    </div>
  );
}
export default injectSheet(styles)(ResumenComite);
