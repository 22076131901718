import { headerBackground } from '../../images';

export default theme => ({
  mainHeader: {
    background: `url(${headerBackground}) no-repeat center center`,
    backgroundSize: 'cover',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    zIndex: 0,
    height: '100%',
  },
  mainHeaderContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',
    '@media (min-width: 576px)': {
      maxWidth: 540,
    },
    '@media (min-width: 768px)': {
      maxWidth: 720,
    },
    '@media (min-width: 992px)': {
      maxWidth: 960,
    },
    '@media (min-width: 1200px)': {
      maxWidth: 1280,
    },
  },
  menuBtn: {
    '& .anticon': {
      color: theme.palette.secondary.main,
    },
    color: theme.palette.text.main,
    fontSize: 18,
  },
  menuBtnText: {
    fontWeight: 1000,
    paddingRight: 3,
  },
  logo: {
    display: 'block',
    width: 130,
    margin: [[10, 'auto']],
  },
  userMenuBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  userMenuAvatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: 5,
  },
});
