export default {
    confirmBtn: {
        width: "100%",
  backgroundColor:" #222",
  borderRadius:" 4px",
  borderStyle:" none",
  boxSizing:" border-box",
  color: "#fff",
  cursor: "pointer",
  display: "inline-block",
  fontFamily: "sans-serif",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "1.5",
  margin: 0,
  maxWidth: "none",
  minHeight:" 40px",
  minWidth: "10px",
  outline: "none",
  overflow: "hidden",
  padding:" 2px 2px 2px",
  position:" relative",
  textAlign:" center",
  textTransform:" none",
  userSelect:"none",

  touchAction:"manipulation",
  width: "100%",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "#333",
  },
  "&:active": {
    backgroundColor: "#333",
  },
  "&:focus": {
    backgroundColor: "#333",
  },
    },

   
    forgotPwd: {
        float: "right",
    },
    responsiveInput: {
        height: "40px",
        width: "100%",
        '@media (max-width: 768px)': {
          height: "30px", 
   
        },
       
      },
}








