const NS = 'FLOW_DATA';

export const actionTypes = {
  TOGGLE_TRANSACTION: `${NS}/TOGGLE_TRANSACTION`,
  EDIT_TRANSACTION: `${NS}/EDIT_TRANSACTION`,
  CLEAR_TRANSACTIONS: `${NS}/CLEAR_TRANSACTIONS`,
  SET_RELATIONS: `${NS}/SET_RELATIONS`,
  TOGGLE_ORDER: `${NS}/TOGGLE_ORDER`,
};

const actions = {
  toggleTransaction: (transaction, group) => ({
    type: actionTypes.TOGGLE_TRANSACTION,
    transaction,
    group,
  }),
  editTransaction: (record, values) => ({
    type: actionTypes.EDIT_TRANSACTION,
    record,
    values,
  }),
  clearTransactions: () => ({
    type: actionTypes.CLEAR_TRANSACTIONS,
  }),
  setRelations: (relations) => ({
    type: actionTypes.SET_RELATIONS,
    relations,
  }),
  toogleOrder: (orderFlag) => ({
    type: actionTypes.TOGGLE_ORDER,
    orderFlag: orderFlag
  }),
};

export default actions;
