/**
 * MainTheme
 *
 * Sets all the main configuration for the theming using JSS.
 */

export default {
  typography: {
    fontFamily: "sans-serif", 
    fontSize: 16, 
    fontWeight: "semi-bold",

  },
  palette: {
    primary: {
      extraLight: "#E5EAEE",
      light: "#CCD7DF",
      main: "#00395C",
    },
    secondary: {
      extraLight: "#FBF8ED",
      light: "#EFE7BA",
      main: "#D5B75B",
    },
    text: {
      main: "#8A8C8E",
    },
    origin: {
      main: "#EA9999",
    },
    destiny: {
      main: "#b6d7a8",
    },
    greenInput: {
      main: "#b6d7a7",
    },
    yellowInput: {
      main: "#ffe599",
    },
  },
};
