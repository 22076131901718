import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import injectSheet from 'react-jss';
import { logoCoreCapital } from "../../images";
import HomePage from '../../containers/HomePage/Loadable';
import FlujoEdifica from '../../containers/FlujoEdifica/Loadable';
import FlujoInversionista from '../../containers/FlujoInversionista/Loadable';
import HistoricoAportes from '../HistoricoAportes/Loadable';
import Projections from '../Projections/Loadable';
import Projection from '../Projection/Loadable';
import LibroDemadas from '../LibroDemandas/Loadable'
import ListaOrdenes from '../ListaOrdenes/Loadable'
import LoginPage from '../../containers/Login';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { PrivateRoute } from '../../components/PrivateRoute';
import styles from './styles';
import ResumenComite from '../ResumenComite';

const { Content } = Layout;

function App(props) {
  const { classes } = props;
  const [isLogged, setIsLogged] = useState(localStorage.getItem('token')? true: false);

  function handleOnLogged() {
    setIsLogged(true);
  }

  return (
    <>
  {/* <Layout> */}
   
    
  
      <Helmet titleTemplate="%s - Core Capital" defaultTitle="Aplicación Core Capital">
        <meta name="description" content="Basic App"/>
      </Helmet>
      <Header isLogged={isLogged}/>
   
    

  {/* <Content className={isLogged ? classes.container  : classes. containerLoginScreen}> */}
        <Switch>
          <Route exact path="/login" render={() => (<LoginPage onLogged={handleOnLogged}/>)} />
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/flujo/edifica" component={FlujoEdifica} />
          <PrivateRoute exact path="/resumen/comite" component={ResumenComite} />
          <PrivateRoute exact path="/flujo/inversionista" component={FlujoInversionista} />
          <PrivateRoute exact path="/proyecciones/" component={Projections} />
          <PrivateRoute exact path="/proyecciones/:id" component={Projection} />
          <PrivateRoute exact path="/proyecciones/nueva" component={Projection} />
          <PrivateRoute exact path="/historico-aportes" component={HistoricoAportes} />
          <PrivateRoute exact path="/libro-demanda" component={LibroDemadas} />
          <PrivateRoute exact path="/libro-ordenes" component={ListaOrdenes} />
        </Switch>
      {/* </Content> */}
      {/* // <Footer/>
    // </Layout> */}
    </>
  );
}

export default injectSheet(styles)(App)
