import React from "react";
import { Menu, Dropdown, Col, Row, Button, Icon, Avatar, Layout } from "antd";
import { NavLink } from "react-router-dom";
import injectSheet from "react-jss";

import api from "../../utils/api";
import { mainLogo, logoCoreCapital } from "../../images";
import styles from "./styles";
import { setUserInfo, delUserInfo, delAuthToken } from "../../utils/storage";
import { userCanView } from "../../utils/user";

const { Header } = Layout;

class MainHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };

    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    if (this.props.isLogged && !this.state.user.username) this.fetchUserInfo();
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    if (this.props.isLogged && !this.state.user.username) this.fetchUserInfo();
  }

  async fetchUserInfo() {
    const { data } = await api.get("accounts/users/info/");
    console.log('User Info from storage:', data);
    setUserInfo(data);
    this.setState({
      user: data,
    });

  }

  logout(ev) {
    api
      .post(`auth/logout/`)
      .then(() => {
        delAuthToken();
        delUserInfo();
        window.location = "/login";
      })
      .catch((err) => {
        delAuthToken();
        delUserInfo();
        window.location = "/login";
      });
  }

  render() {
    const { classes, isLogged } = this.props;
    const userPermission = userCanView();

    const mainMenu = (
      <Menu className={{ padding: "10px" }}>
        {userPermission ? (
          <>
          <div style={{display:"flex",flexDirection:"column", gap:"2vh",padding:2}}>
              <Menu.Item key="mainMenu1">
              <NavLink to={"/"} style={{ padding: "10px" }}>
                Comité de levantamientos
              </NavLink>
            </Menu.Item>
            <Menu.Item key="mainMenu2">
              <NavLink to={"/resumen/comite"} style={{ padding: "10px" }}>
                Resumen Comité
              </NavLink>
            </Menu.Item>
            <Menu.Item key="mainMenu3" style={{ padding: "10px" }}>
              <NavLink to={"/flujo/inversionista"}>Flujo Inversionista</NavLink>
            </Menu.Item>
            <Menu.Item key="mainMenu4" style={{ padding: "10px" }}>
              <NavLink to={"/libro-demanda"}>Reporte de Demandas</NavLink>
            </Menu.Item>
            <Menu.Item key="mainMenu5" style={{ padding: "10px" }}>
              <NavLink to={"/libro-ordenes"}>Libro de Demandas</NavLink>
            </Menu.Item>
          </div>
          
          </>
        ) : (
          <>
            <Menu.Item key="mainMenu3" style={{ padding: "10px" }}>
              <NavLink to={"/flujo/inversionista"}>Flujo Inversionista</NavLink>
            </Menu.Item>
            <Menu.Item key="mainMenu6" style={{ padding: "10px" }}>
              <NavLink to={"/libro-demanda"}>Reporte de Demandas</NavLink>
            </Menu.Item>
            <Menu.Item key="mainMenu7" style={{ padding: "10px" }}>
              <NavLink to={"/libro-ordenes"}>Libro de Demandas</NavLink>
            </Menu.Item>
          </>
        )}
      </Menu>
    );
    {
      /* <Menu.Item key="mainMenu2">
          <NavLink to={'/flujo/edifica'}>Flujo Edifica Total</NavLink>
        </Menu.Item> */
    }
    {
      /* <Menu.Item key="mainMenu4">
          <NavLink to={'/proyecciones'}>Proyecciones</NavLink>
        </Menu.Item> */
    }
    {
      /* <Menu.Item key="mainMenu5">
          <NavLink to={'/historico-aportes'}>Histórico de Aportes</NavLink>
        </Menu.Item> */
    }
    const userMenu = (
      <Menu>
        <Menu.Item key="1" onClick={this.logout}>
          <Icon type="logout" />
          Cerrar Sesión
        </Menu.Item>
      </Menu>
    );

    return (
      <Header id={"main-header"} className={classes.mainHeader}>
        <div className={classes.mainHeaderContainer}>
          <Row type="flex" justify="space-between" align="middle">
            {isLogged && (
              <Col span={8}>
                <Dropdown
                  overlay={mainMenu}
                  placement="bottomLeft"
                  trigger={["click"]}
                >
                  <Button
                    icon="menu"
                    type="link"
                    size="large"
                    // className={classes.menuBtn}
                  >
                    {/* <span className={classes.menuBtnText}>CORE</span>CAPITAL */}
                  </Button>
                </Dropdown>
              </Col>
            )}

            {isLogged && (
              <Col span={8}>
                {window.innerWidth > 500 && (
                  <img
                    className={classes.logo}
                    src={logoCoreCapital}
                    alt="CORE Capital"
                  />
                )}
              </Col>
            )}
            {isLogged && (
              <Col span={8} className={classes.userMenuBtnWrapper}>
                <Dropdown
                  overlay={userMenu}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <Button type="link" size="large">
                    <Avatar icon="user" className={classes.userMenuAvatar} />
                    {this.state.user.username}
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </Col>
            )}
          </Row>
        </div>
      </Header>
    );
  }
}

MainHeader.propTypes = {};

export default injectSheet(styles)(MainHeader);
