export default theme => ({
  loginWrapper: {
    // paddingTop: "30px",
    background:"#ffffffed",
  
    padding:"20px",
    borderRadius:"5px",
    boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    "@media (min-width: 576px)": {
      width:"100%",
      maxWidth: "100%",
      
    },
    "@media (min-width: 768px)": {
      width:"100%",
      maxWidth: "100%",
    },
    "@media (min-width: 992px)": {
      width:"100%",
      maxWidth: "100%",
    },
    "@media (min-width: 1200px)": {
      width:"30%",
      padding: "2%",
    },
  },
  containerLoginScreen: {
    display:"flex",
    width: "100%",
    height: "100vh",
    justifyContent:"center",
    alignItems:"center",
    marginRight: "auto",
    marginLeft: "auto",
    background: `url(https://corecapital.com.pe/images/inicio/bg1.jpg) no-repeat center center`,
    backgroundColor: "#A6C1D3",
    // boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    "@media (min-width: 576px)": {
      maxWidth: "100%",
      padding: "0%",
      justifyContent:"left",
      padding: "1%",
    },
    "@media (min-width: 768px)": {
      maxWidth: "100%",
      padding: "1%",
    },
    "@media (min-width: 992px)": {
      maxWidth: "100%",
      padding: "1%",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "100%",
      padding: "2%",
      justifyContent:"center",
      alignItems:"center",
      background: `url(https://corecapital.com.pe/images/inicio/bg1.jpg) no-repeat `,
      backgroundSize: 'cover',
    },
  },
  logo: {
    display: 'block',
    width: 240,
    margin: [[10, 'auto']],
    position:"absolute",
    left:"5%",
    top:"5%"
  },
  loginTitle: {
    color: `#222 !important`,
    textAlign: 'center',
    paddingBottom:"10px",
    fontFamily:"sans-serif",
    fontWeight:"bold",
    fontSize:"1.5rem"
  }
});
