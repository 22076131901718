import React from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import injectSheet from 'react-jss';
import { Redirect } from 'react-router-dom';
import api from '../../../utils/api';
import { setAuthToken, getAuthToken } from '../../../utils/storage';

import styles from "./styles";

class NormalLoginForm extends React.PureComponent {
  constructor(props) {
      super(props);
      this.state = {
          redirectToReferrer: getAuthToken() ? true : false,
          submitted: false,
          loading: false,
          error: false
      };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {username, password } = values;
        api.post(`auth/api-token-auth/`, {
          username: username,
          password: password
        })
        .then(res => {
          setAuthToken(res.data.token);
          this.setState({error: false});
          this.props.onLogged();
          this.setState({redirectToReferrer: true });
        })
        .catch(err => {
          this.setState({error: true});
        })
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { classes } = this.props;
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to='/' />
    }
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Ingrese su usuario' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Usuario"
              // style={{height:"40px"}}
              className={classes.responsiveInput} 
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Ingrese su contraseña' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Contraseña"
              // style={{height:"40px"}}
              className={classes.responsiveInput} 
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className={ classes.confirmBtn }>
            Iniciar Sesión
          </Button>
        </Form.Item>
        {
          this.state.error &&
            <Alert message="Usuario y/o contraseña incorrecta" type="error"></Alert>
        }
      </Form>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export default injectSheet(styles)(WrappedNormalLoginForm);
