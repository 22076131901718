export default (theme) => ({
  // customForm:{
  //   .ant-form
  // },
  enabledOrderListRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.extraLight,
    },
  },
  disabledOrderListRow: {
    cursor: "pointer",
    "border-top-style": "solid",
    "border-top-color": "#8A8C8E",
    "text-decoration": "line-through",
    "&:hover": {
      backgroundColor: theme.palette.primary.extraLight,
    },
  },
  ordersTableWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
  },
  downloadBtn: {
    marginBottom: 20,
    alignSelf: "flex-end",
  },
  customCard: {
    "& .ant-card-head": {
      backgroundColor: theme.palette.secondary.extraLight,
      "& .ant-card-head-title": {
        color: theme.palette.secondary.main,
        fontSize: 20,
        fontWeight: "bold",
      },
      "& .ant-card-extra": {
        padding: 0,
        "& .ant-btn": {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  containerForm: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    width: "100%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    background: "#fff",
    backgroundColor: "#fff",
    // boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    "@media (min-width: 576px)": {
      maxWidth: "100%",
      padding: "0%",
    },
    "@media (min-width: 768px)": {
      maxWidth: "100%",
      padding: "0%",
    },
    "@media (min-width: 992px)": {
      maxWidth: "100%",
      padding: "0%",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "100%",
      padding: "3%",
    },
  },
});
