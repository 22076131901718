import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import flowData from './flow-data/reducer';


const rootReducer = history => combineReducers({
  router: connectRouter(history),
  flowData,
});

export default rootReducer;
