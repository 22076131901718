import { actionTypes } from './actions';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import findIndex from 'lodash/findIndex';


const ORIGIN = 'origin';
const DESTINATION = 'destination';


const initialState = {
  origin: [],
  destination: {},
  all: {},
  order: {},
  relations: {},
  orderFlag: ORIGIN,
};

const udpateRatios = (origin, destination) => {
  return origin.map((transaction) => {
    let ratio = 0.5;
    if(transaction.family !== undefined && transaction.family === destination.family) {
      ratio = 0.7;
    }
    const relocation = ratio * transaction.amount;
    return {
      ratio,
      relocation,
      ...transaction,
    }
  });
}


const transactionsReducer = (state = initialState, action) => {
  if(action.type === actionTypes.TOGGLE_TRANSACTION) {
    const { group, transaction } = action;
    const {
      origin: oldOrigin,
      order: oldOrder,
      destination: oldDestination,
      all: oldAll,
      orderFlag,
    } = state;
    const order = { ...oldOrder };
    let origin = [ ...oldOrigin ];
    let destination = { ...oldDestination };
    let all = { ...oldAll };

    if (isEmpty(oldOrder)) {
      order.Usos = DESTINATION;
      order.Fuentes = ORIGIN;
    }
    if (all.hasOwnProperty(transaction.id)) {
      // If Transaction exists, then delete
      if (all[transaction.id] === ORIGIN) {
        remove(origin, o => o.id === transaction.id);
      } else if(all[transaction.id] === DESTINATION) {
        destination = {};
      }
      delete all[transaction.id];

    } else {
      // If Transaction not exists, then add
      if (orderFlag === ORIGIN && !isEmpty(all) && order[group] !== orderFlag) {
        return {...state};
      }
      if (orderFlag === DESTINATION && !isEmpty(origin) && order[group] !== orderFlag) {
        return {...state};
      }
      if (orderFlag === ORIGIN) {
        // If it is origin then check the case: Usos or Fuentes
        all[transaction.id] = ORIGIN;
        const newTransaction = { ...transaction };
        if (group === 'Levantamientos') {
          if (origin.length > 0) {
            delete all[origin[0].id];
          }
          origin = [newTransaction];
          order.Usos = ORIGIN;
          order.Fuentes = DESTINATION;
        } else {
          origin.push(newTransaction);
          order.Usos = DESTINATION;
          order.Fuentes = ORIGIN;
        }
      } else {
        all[transaction.id] = DESTINATION;
        delete all[destination.id];
        destination = { ...transaction };
        if (group === 'Levantamientos') {
          order.Usos = DESTINATION;
          order.Fuentes = ORIGIN;
        } else {
          order.Usos = ORIGIN;
          order.Fuentes = DESTINATION;
        }
      }

      if (!isEmpty(destination)) {
        origin = udpateRatios(origin, destination);
      }
    }

    return {
      ...state,
      order,
      origin,
      destination,
      all,
    };
  }

  if (action.type === actionTypes.EDIT_TRANSACTION) {
    const { record, record: { id, amount, ratio } } = action;
    const { origin: oldOrigin }  = state;
    const origin = [ ...oldOrigin ];
    const index = findIndex(origin, { id } );
    if (index >= 0) {
      origin[index] = { ...record, relocation: amount * ratio };
    }
    return {
      ...state,
      origin,
    };
  }

  if (action.type === actionTypes.CLEAR_TRANSACTIONS) {
    const { relations } = state;
    return {
      ...initialState,
      relations,
    };
  }

  if (action.type === actionTypes.SET_RELATIONS) {
    const relations = {};
    action.relations.forEach((relation) => {
      relations[relation.transaction_id] = relation;
    })

    return {
      ...state,
      relations,
    };
  }

  if (action.type === actionTypes.TOGGLE_ORDER) {
    const { orderFlag } = action;
    return {
      ...state,
      orderFlag,
    }
  }

  return state;
};

export default transactionsReducer;
