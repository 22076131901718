/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'babel-polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'react-jss';
import esES from 'antd/es/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es';

import App from './containers/App';

// import configureStore from './configureStore';
import store, { history } from './store';

import * as serviceWorker from './serviceWorker';

import theme from './themes/mainTheme';

// Create redux store with history
const MOUNT_NODE = document.getElementById('root');

serviceWorker.register();

moment.locale('es');

const render = () => {
  return ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <ConfigProvider locale={esES}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ConfigProvider>
      </Router>
    </Provider>,
    MOUNT_NODE
  );
};

render();
