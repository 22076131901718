import {
  gothamThin,
  gothamThinItalic,
  gothamUltraLight,
  gothamUltraLightItalic,
  gothamLight,
  gothamLightItalic,
  gothamBook,
  gothamBookItalic,
  gotham,
  gothamItalic,
  gothamBold,
  gothamBoldItalic,
  gothamBlack,
  gothamBlackItalic,
  gothamUltra,
  gothamUltraItalic,
} from "../../fonts";

export default (theme) => ({
  "@global": {
    "@font-face": [
      {
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: 100,
        src: `url(${gothamThin}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "italic",
        fontWeight: 100,
        src: `url(${gothamThinItalic}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: 200,
        src: `url(${gothamUltraLight}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "italic",
        fontWeight: 200,
        src: `url(${gothamUltraLightItalic}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: 300,
        src: `url(${gothamLight}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "italic",
        fontWeight: 300,
        src: `url(${gothamLightItalic}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: 400,
        src: `url(${gothamBook}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "italic",
        fontWeight: 400,
        src: `url(${gothamBookItalic}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: 500,
        src: `url(${gotham}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "italic",
        fontWeight: 500,
        src: `url(${gothamItalic}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: 700,
        src: `url(${gothamBold}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "italic",
        fontWeight: 700,
        src: `url(${gothamBoldItalic}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: 800,
        src: `url(${gothamBlack}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "italic",
        fontWeight: 800,
        src: `url(${gothamBlackItalic}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "normal",
        fontWeight: 900,
        src: `url(${gothamUltra}) format('truetype')`,
      },
      {
        fontFamily: "Gotham",
        fontStyle: "italic",
        fontWeight: 900,
        src: `url(${gothamUltraItalic}) format('truetype')`,
      },
    ],
    body: {
      fontFamily: '"Gotham", "Arial", sans-serif',
      background: "#F5F5F5",
    },
    a: {
      color: theme.palette.primary.main,
    },
    li: {
      "&.ant-dropdown-menu-item:hover, &.ant-dropdown-menu-submenu-title:hover":
        {
          backgroundColor: theme.palette.primary.light,
        },
    },
    table: {
      "& .ant-table-thead > tr > th": {
        color: theme.palette.primary.main,
      },
      "& .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, & .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, & .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, & .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td":
        {
          background: "transparent",
        },
    },
    ".customAlert": {
      "& .ant-notification-notice-message": {
        color: theme.palette.primary.main,
        fontWeight: "bold",
      },
    },
    ".customCard": {

      "&:not(:last-child)": {
        marginBottom: 40,
    
      },
      "& .ant-card-head": {
        backgroundColor: theme.palette.secondary.extraLight,
        "& .ant-card-head-title": {
          color: theme.palette.secondary.main,
          fontSize: 20,
          fontWeight: "bold",
        },
        "& .ant-card-extra": {
          padding: 0,
          "& .ant-btn": {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    ".customCardDevo": {
      "&:not(:last-child)": {
        marginBottom: 40,
      },
      "& .ant-card-head": {
        backgroundColor: "#FAE6D2",
        "& .ant-card-head-title": {
          color: "#F97A00",
          fontSize: 18,
          fontWeight: "bold",
        },
        "& .ant-card-extra": {
          padding: 0,
          "& .ant-btn": {
            color: "#F97A00",
          },
        },
      },
    },
    ".customCardLev": {
      "&:not(:last-child)": {
        marginBottom: 40,
      },
      "& .ant-card-head": {
        backgroundColor: "#D1DAE0",
        "& .ant-card-head-title": {
          color: theme.palette.primary.main,
          fontSize: 18,
          fontWeight: "bold",
        },
        "& .ant-card-extra": {
          padding: 0,
          "& .ant-btn": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    ".customInnerCard": {
      "&:not(:last-child)": {
        marginBottom: 24,
    
      },
      "&.ant-card-type-inner": {
        "& .ant-card-head": {
          backgroundColor: theme.palette.primary.extraLight,
          "& .ant-card-head-title": {
            color: theme.palette.primary.main,
            fontSize: "medium",
            fontWeight: "bold",
          },
          "& .ant-card-extra": {
            padding: 0,
            "& .ant-btn": {
              color: theme.palette.primary.main,
            },
          },
        },
      },
      "@media (max-width: 768px)": {
        width: "100%",
        height: "100%",
        fontSize:"0.5rem"
      },
      width: "100%",
      height: "100%",
    },
    ".customInnerCard2": {
      "&:not(:last-child)": {
        marginBottom: 24,
      },
      "&.ant-card-type-inner": {
        "& .ant-card-head": {
          backgroundColor: "#FAE6D2",
          "& .ant-card-head-title": {
            color: "#F97A00",
            fontSize: "medium",
            fontWeight: "bold",
          },
          "& .ant-card-extra": {
            padding: 0,
            "& .ant-btn": {
              color: "#F97A00",
            },
          },
        },
      },
    },

    ".customModal": {
      "& .ant-modal-title": {
        color: theme.palette.primary.main,
      },
    },
    ".ant-calendar-range-picker-input": {
      height: "100%",
    },
  },
  // container: {
  //   width: '100%',
  //   padding: [[40, 20]],
  //   marginRight: 'auto',
  //   marginLeft: 'auto',
  //   backgroundColor: '#fff',
  //   boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  //   '@media (min-width: 576px)': {
  //     maxWidth: 540,
  //   },
  //   '@media (min-width: 768px)': {
  //     maxWidth: 720,
  //   },
  //   '@media (min-width: 992px)': {
  //     maxWidth: 960,
  //   },
  //   '@media (min-width: 1200px)': {
  //     maxWidth: 1280,
  //   },
  // }

  logo: {
    display: 'block',
    width: 240,
    margin: [[10, 'auto']],
    position:"absolute",
    left:"5%"
  },
  container: {
    width: "100%",
    padding: "5%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    background: `url(https://corecapital.com.pe/images/inicio/bg1.jpg) no-repeat center center`,
    backgroundColor: "#fff",
    // boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    "@media (min-width: 576px)": {
      maxWidth: "100%",
      padding: "0%",
    },
    "@media (min-width: 768px)": {
      maxWidth: "100%",
    },
    "@media (min-width: 992px)": {
      maxWidth: "100%",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "100%",
      padding: "2%",
    },
  },
  containerLoginScreen: {
    width: "100%",
    padding: "5%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    background: `url(https://corecapital.com.pe/images/inicio/bg1.jpg) no-repeat center center`,
    backgroundColor: "#fff",
    // boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    "@media (min-width: 576px)": {
      maxWidth: "100%",
      padding: "0%",
    },
    "@media (min-width: 768px)": {
      maxWidth: "100%",
    },
    "@media (min-width: 992px)": {
      maxWidth: "100%",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "100%",
      padding: "2%",
    },
  },
});

// background: url(/static/media/headerBackground.3e839765.jpg) no-repeat center center;