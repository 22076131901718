import React from 'react';
import { Col, Row } from "antd";
import injectSheet from 'react-jss';

import { footerLogo } from '../../images';
import styles from "./styles";

function Footer({ classes }) {

  return (
    <footer className={classes.footer}>
      <Row>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }}>
          <img src={footerLogo} alt=""/>
        </Col>
      </Row>
    </footer>
  );
}

Footer.propTypes = {

};

export default injectSheet(styles)(Footer);